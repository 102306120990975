.wrapper {
  @apply fixed left-0 top-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-standarts-black;
  z-index: 110;
}

.inner {
  @apply bg-standarts-white text-standarts-black relative overflow-x-hidden;
  overflow: scroll !important;
  border-radius: 5px;
  max-height: 90%;
  padding: 30px;
  position: relative; /* Değişiklik: relative olarak ayarlandı */

  button,
  ul {
    @apply focus:outline-none;
  }
}

.responsiveInner {
  @screen mobile {
    @apply w-[85%];
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 111;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}

.print {
  @apply absolute cursor-pointer text-silver font-semibold top-0;
  font-size: 16px;
  line-height: 24px;
  right: 48px;
  padding: 17px;
  transition: all 0.2 ease-in-out;
}

.title {
  @apply text-black font-bold;
  font-size: 18px;
  line-height: 25px;
}
